@import "../../assets/styles/utils";

.index-banner{
    width: 100%;
    box-sizing: border-box;
    position: relative;
    @include res(height,100vh,(md:600px,sm:400px));
    @include res(padding-top,$header-height-base,$header-height-ratio);
    @include res(padding-left,120px,10 / 120);
    @include res(padding-right,120px,10 / 120);
    @include res(padding-bottom,120px,10 / 120);
    &::after{
        content: '';
        display: block;
        background-color: $color-main;
        opacity: .95;
        position: absolute;
        bottom: 0;
        z-index: 1;
        @include res(height,100%,(xs:30%));
        @include res(left,240px, 10 / 240);
        @include res(width,27%,(mmd:32%,xs:calc(100% - 20px)));
    }
    .swiper{
        height: 100%;
        .swiper-slide {
            overflow:hidden;
            .slide-inner {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-size: cover;
                background-position: center;
            }
        }
    }
    .banner-text{
        position: absolute;
        z-index: 2;
        color: #fff;
        @include res(bottom,160px,30 / 160);
        @include res(width,27%,(mmd:32%,xs:calc(100% - 20px)));
        @include res(left,240px, 10 / 240);
        .item{
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
            overflow: hidden;
            @include res(padding-left,90px, 10 / 90);
            @include res(padding-right,90px, 10 / 90);
            &.active{
                opacity: 1;
                visibility: visible;
                .t2 span{
                    transform: translateY(0);
                }
            }
            .t1{
                line-height: 1.55;
                @include res(white-space,normal,(xs:nowrap));
                @include res(font-size,36px, 16 / 36);
            }
            .t2{
                font-family: 'roboto-b';
                line-height: 1.55;
                text-transform: uppercase;
                white-space: nowrap;
                @include res(line-height,1.55, 1.2 / 1.55);
                @include res(font-size,37px, 16 / 37);
                &::before{
                    content: '';
                    display: block;
                    height: 1px;
                    background-color: #fff;
                    @include res(margin-top,45px, 15 / 45);
                    @include res(margin-bottom,30px, 15 / 30);
                    @include res(width,100%);
                }
                span{
                    transition: all .6s $anime-bezier;
                    display: block;
                    transform: translateY(100%);
                }
            }
        }
    }
    .swiper-pagination{
        position: absolute;
        z-index: 3;
        text-align: left;
        @include res(bottom,70px, 0 / 70);
        @include res(left,240px, 10 / 240);
        @include res(padding-left,90px, 10 / 90);
        @include res(padding-right,90px, 10 / 90);
        @include res(width,27%,(mmd:32%,xs:calc(100% - 20px)));
        .swiper-pagination-bullet{
            background-color: transparent;
            border: 1px solid #fff;
            opacity: 1;
            transition: all .3s;
            @include res(width,12px, 10 / 12);
            @include res(height,12px, 10 / 12);
            &.swiper-pagination-bullet-active{
                background-color: #fff;
            }
        }
    }
}

.index-about{
    @include res(padding-top,20px, 20 / 20);
    @include res(margin-bottom,130px, 30 / 130);
    .title{
        color: $color-main;
        @include res(line-height,1.44);
        @include res(font-size,50px, 22 / 50);
        @include res(padding-left,50%,(md:35%,sm:20%,xs:0));
        @include res(margin-bottom,70px, 20 / 70);
    }
    .cont{
        align-items: center;
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        @include res(padding-left,120px, 0 / 120);
        @include res(padding-right,120px, 0 / 120);
        .text{
            flex-grow: 1;
            color: #000;
            @include res(font-size,20px, 16 / 20);
            @include res(line-height,2, 1.6 / 2);
            &::before{
                content: '';
                display: block;
                height: 1px;
                background-color: $color-main;
                @include res(width,80px, 50 / 80);
                @include res(margin-bottom,30px, 10 / 30);
            }
        }
        .link{
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            @include res(margin-top,0,(xs:20px));
            @include res(margin-left,20%,(md:10%,xs:0));
        }
    }
}

.scroll-text{
    overflow: hidden;
    font-family: 'roboto-b';
    &.slide-right{
        .text1{
            .txt-scroll{
                .scrollbox{
                    justify-content: flex-end;
                    .txt{
                        animation: myslideRight 30s infinite linear;
                    }
                }
            }
        }
    }
    .text1{
		line-height: .9;
		text-transform: uppercase;
		color: $color-main;
		width: 100%;
		-webkit-font-smoothing: antialiased;
		-webkit-text-stroke: 1px rgba(255,255,255,.3);
		@include res(font-size,110px, 40 / 110);
		.txt-scroll{
			.scrollbox{
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				flex-wrap: nowrap;
				white-space: nowrap;
				.txt{
					animation: myslideLeft 30s infinite linear;
				}
			}
		}
	}
}

@keyframes myslideLeft {
  0% {
		transform: translateX(-10%);
  }
  100% {
		transform: translateX(-90%);
  }
}
@keyframes myslideRight {
  0% {
	  transform: translateX(10%);
  }
  100% {
	  transform: translateX(90%);
  }
}

.index-product{
    position: relative;
    @include res(padding-top,55px, 10 / 55);
    @include res(margin-bottom,230px, 50 / 230);
    .bg-img{
        position: absolute;
        left: 0;
        bottom: -10%;
        z-index: -1;
        transform: translateX(-50%);
        img{
            display: block;
            height: auto;
            @include res(width,1044px, 300 / 1044);
        }
    }
    .product{
        ul{
            li{
                align-items: center;
                justify-content: space-between;
                @include res(display,flex,(xs:block));
                @include res(margin-top,75px, 20 / 75);
                &:nth-child(even){
                    flex-direction: row-reverse;
                    .item-text{
                        @include res(padding-left,280px, 0 / 280);
                    }
                }
                .item-img{
                    flex-shrink: 0;
                    position: relative;
                    overflow: hidden;
                    @include res(width,40.5%,(xs:100%));
                    img{
                        display: block;
                        width: 100%;
                        height: auto;
                        opacity: 0;
                    }
                    b{
                        display: block;
                        width: 100%;
                        height: 130%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-position: center;
                        background-size: cover;
                    }
                }
                .item-text{
                    flex-shrink: 0;
                    box-sizing: border-box;
                    @include res(width,59.5%,(xs:100%));
                    @include res(padding-top,50px, 20 / 50);
                    @include res(padding-bottom,50px, 20 / 50);
                    @include res(padding-right,50px, 0 / 50);
                    @include res(padding-left,140px, 0 / 140);
                    .t1{
                        color: $color-main;
                        @include res(font-size,20px, 16 / 20);
                        @include res(margin-bottom,20px, 10 / 20);
                    }
                    .t2{
                        color: #000;
                        font-family: 'roboto-b';
                        @include res(font-size,50px, 24 / 50);
                        @include res(margin-bottom,40px, 15 / 40);
                    }
                    .t3{
                        color: #000;
                        line-height: 1;
                        @include res(font-size,20px, 16 / 20);
                        @include res(margin-bottom,110px, 20 / 110);
                        span{
                            display: inline-block;
                            @include res(margin-bottom,10px, 10 / 10);
                            &:not(:last-child){
                                @include res(margin-right,35px, 10 / 35);
                            }
                        }
                    }
                    .link{
                        @include res(text-align,null,(xs:right));
                    }
                }
            }
        }
    }
}

.index-quality{
    align-items: stretch;
    justify-content: stretch;
    @include res(display,flex,(xs:block));
    @include res(margin-bottom,130px, 30 / 130);
    .left{
        flex-shrink: 0;
        box-sizing: border-box;
        background-color: $color-main;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        @include res(text-align,right);
        @include res(width,34%,(xs:100%));
        @include res(transform,translateY(-70px),(xs:translateY(0)));
        @include res(padding-top,50px, 20 / 50);
        @include res(padding-bottom,50px, 20 / 50);
        @include res(padding-right,65px, 15 / 65);
        @include res(padding-left,100px, 15 / 100);
        .t1{
            font-family: 'roboto-b';
            line-height: .8;
            text-transform: uppercase;
            @include res(font-size,80px,30 / 80);
            @include res(margin-bottom,85px, 20 / 85);
        }
        .t2{
            @include res(font-size,20px, 16 / 20);
            @include res(margin-bottom,55px, 20 / 55);
            p{
                &:not(:last-child){
                    @include res(margin-bottom,30px, 10 / 30);
                }
            }
        }
    }
    .right{
        flex-shrink: 0;
        @include res(width,66%,(xs:100%));
        img{
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.index-news{
    @include res(margin-bottom,140px, 30 / 140);
    .cont{
        @include res(padding-left,120px, 0 / 120);
        @include res(padding-right,120px, 0 / 120);
        .news-title{
            color: $color-main;
            @include res(font-size,20px, 16 / 20);
            @include res(margin-bottom,50px, 20 / 50);
        }
        .link{
            text-align: center;
            @include res(margin-top,0,(xs:20px));
        }
    }
}