.index-banner {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: 100vh;
  padding-top: 120px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 120px;
}

@media (max-width: 1024px) {
  .index-banner {
    height: 600px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: 400px;
  }
}

@media (max-width: 1600px) {
  .index-banner {
    padding-top: 108px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    padding-top: 96px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    padding-top: 84px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    padding-top: 72px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    padding-top: 60px;
  }
}

@media (max-width: 1600px) {
  .index-banner {
    padding-left: 98px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    padding-left: 76px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    padding-left: 54px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner {
    padding-right: 98px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    padding-right: 76px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    padding-right: 54px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    padding-right: 32px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner {
    padding-bottom: 98px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    padding-bottom: 76px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    padding-bottom: 54px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    padding-bottom: 32px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    padding-bottom: 10px;
  }
}

.index-banner::after {
  content: '';
  display: block;
  background-color: #c51333;
  opacity: .95;
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 100%;
  left: 240px;
  width: 27%;
}

@media (max-width: 767px) {
  .index-banner::after {
    height: 30%;
  }
}

@media (max-width: 1600px) {
  .index-banner::after {
    left: 194px;
  }
}

@media (max-width: 1366px) {
  .index-banner::after {
    left: 148px;
  }
}

@media (max-width: 1024px) {
  .index-banner::after {
    left: 102px;
  }
}

@media (max-width: 991px) {
  .index-banner::after {
    left: 56px;
  }
}

@media (max-width: 767px) {
  .index-banner::after {
    left: 10px;
  }
}

@media (max-width: 1366px) {
  .index-banner::after {
    width: 32%;
  }
}

@media (max-width: 767px) {
  .index-banner::after {
    width: calc(100% - 20px);
  }
}

.index-banner .swiper {
  height: 100%;
}

.index-banner .swiper .swiper-slide {
  overflow: hidden;
}

.index-banner .swiper .swiper-slide .slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
}

.index-banner .banner-text {
  position: absolute;
  z-index: 2;
  color: #fff;
  bottom: 160px;
  width: 27%;
  left: 240px;
}

@media (max-width: 1600px) {
  .index-banner .banner-text {
    bottom: 134px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text {
    bottom: 108px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text {
    bottom: 82px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text {
    bottom: 56px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text {
    bottom: 30px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text {
    width: 32%;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text {
    width: calc(100% - 20px);
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-text {
    left: 194px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text {
    left: 148px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text {
    left: 102px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text {
    left: 56px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text {
    left: 10px;
  }
}

.index-banner .banner-text .item {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  overflow: hidden;
  padding-left: 90px;
  padding-right: 90px;
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item {
    padding-left: 74px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item {
    padding-left: 58px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item {
    padding-left: 42px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item {
    padding-left: 26px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item {
    padding-right: 74px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item {
    padding-right: 58px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item {
    padding-right: 42px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item {
    padding-right: 26px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item {
    padding-right: 10px;
  }
}

.index-banner .banner-text .item.active {
  opacity: 1;
  visibility: visible;
}

.index-banner .banner-text .item.active .t2 span {
  transform: translateY(0);
}

.index-banner .banner-text .item .t1 {
  line-height: 1.55;
  white-space: normal;
  font-size: 36px;
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t1 {
    white-space: nowrap;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item .t1 {
    font-size: 32px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item .t1 {
    font-size: 28px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item .t1 {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item .t1 {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t1 {
    font-size: 16px;
  }
}

.index-banner .banner-text .item .t2 {
  font-family: 'roboto-b';
  line-height: 1.55;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1.55;
  font-size: 37px;
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item .t2 {
    line-height: 1.48;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item .t2 {
    line-height: 1.41;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item .t2 {
    line-height: 1.34;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item .t2 {
    line-height: 1.27;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t2 {
    line-height: 1.2;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item .t2 {
    font-size: 32.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item .t2 {
    font-size: 28.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item .t2 {
    font-size: 24.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item .t2 {
    font-size: 20.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t2 {
    font-size: 16px;
  }
}

.index-banner .banner-text .item .t2::before {
  content: '';
  display: block;
  height: 1px;
  background-color: #fff;
  margin-top: 45px;
  margin-bottom: 30px;
  width: 100%;
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item .t2::before {
    margin-top: 39px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item .t2::before {
    margin-top: 33px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item .t2::before {
    margin-top: 27px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item .t2::before {
    margin-top: 21px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t2::before {
    margin-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-text .item .t2::before {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-text .item .t2::before {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-text .item .t2::before {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-text .item .t2::before {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-text .item .t2::before {
    margin-bottom: 15px;
  }
}

.index-banner .banner-text .item .t2 span {
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  display: block;
  transform: translateY(100%);
}

.index-banner .swiper-pagination {
  position: absolute;
  z-index: 3;
  text-align: left;
  bottom: 70px;
  left: 240px;
  padding-left: 90px;
  padding-right: 90px;
  width: 27%;
}

@media (max-width: 1600px) {
  .index-banner .swiper-pagination {
    bottom: 56px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination {
    bottom: 42px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination {
    bottom: 28px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination {
    bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination {
    bottom: 0px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper-pagination {
    left: 194px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination {
    left: 148px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination {
    left: 102px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination {
    left: 56px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination {
    left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper-pagination {
    padding-left: 74px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination {
    padding-left: 58px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination {
    padding-left: 42px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination {
    padding-left: 26px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper-pagination {
    padding-right: 74px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination {
    padding-right: 58px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination {
    padding-right: 42px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination {
    padding-right: 26px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination {
    padding-right: 10px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination {
    width: 32%;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination {
    width: calc(100% - 20px);
  }
}

.index-banner .swiper-pagination .swiper-pagination-bullet {
  background-color: transparent;
  border: 1px solid #fff;
  opacity: 1;
  transition: all .3s;
  width: 12px;
  height: 12px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    width: 11.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    width: 11.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    width: 10.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    width: 10.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    height: 11.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    height: 11.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    height: 10.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    height: 10.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet {
    height: 10px;
  }
}

.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
}

.index-about {
  padding-top: 20px;
  margin-bottom: 130px;
}

@media (max-width: 1600px) {
  .index-about {
    padding-top: 20px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-top: 20px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about {
    margin-bottom: 110px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    margin-bottom: 90px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    margin-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .index-about {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .index-about {
    margin-bottom: 30px;
  }
}

.index-about .title {
  color: #c51333;
  line-height: 1.44;
  font-size: 50px;
  padding-left: 50%;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .index-about .title {
    font-size: 44.4px;
  }
}

@media (max-width: 1366px) {
  .index-about .title {
    font-size: 38.8px;
  }
}

@media (max-width: 1024px) {
  .index-about .title {
    font-size: 33.2px;
  }
}

@media (max-width: 991px) {
  .index-about .title {
    font-size: 27.6px;
  }
}

@media (max-width: 767px) {
  .index-about .title {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .title {
    padding-left: 35%;
  }
}

@media (max-width: 991px) {
  .index-about .title {
    padding-left: 20%;
  }
}

@media (max-width: 767px) {
  .index-about .title {
    padding-left: 0;
  }
}

@media (max-width: 1600px) {
  .index-about .title {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .index-about .title {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .index-about .title {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-about .title {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-about .title {
    margin-bottom: 20px;
  }
}

.index-about .cont {
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-left: 120px;
  padding-right: 120px;
}

@media (max-width: 767px) {
  .index-about .cont {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-about .cont {
    padding-left: 96px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont {
    padding-left: 72px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont {
    padding-left: 48px;
  }
}

@media (max-width: 991px) {
  .index-about .cont {
    padding-left: 24px;
  }
}

@media (max-width: 767px) {
  .index-about .cont {
    padding-left: 0px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont {
    padding-right: 96px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont {
    padding-right: 72px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont {
    padding-right: 48px;
  }
}

@media (max-width: 991px) {
  .index-about .cont {
    padding-right: 24px;
  }
}

@media (max-width: 767px) {
  .index-about .cont {
    padding-right: 0px;
  }
}

.index-about .cont .text {
  flex-grow: 1;
  color: #000;
  font-size: 20px;
  line-height: 2;
}

@media (max-width: 1600px) {
  .index-about .cont .text {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .text {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .text {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .text {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .text {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .text {
    line-height: 1.92;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .text {
    line-height: 1.84;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .text {
    line-height: 1.76;
  }
}

@media (max-width: 991px) {
  .index-about .cont .text {
    line-height: 1.68;
  }
}

@media (max-width: 767px) {
  .index-about .cont .text {
    line-height: 1.6;
  }
}

.index-about .cont .text::before {
  content: '';
  display: block;
  height: 1px;
  background-color: #c51333;
  width: 80px;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .index-about .cont .text::before {
    width: 74px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .text::before {
    width: 68px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .text::before {
    width: 62px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .text::before {
    width: 56px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .text::before {
    width: 50px;
  }
}

@media (max-width: 1600px) {
  .index-about .cont .text::before {
    margin-bottom: 26px;
  }
}

@media (max-width: 1366px) {
  .index-about .cont .text::before {
    margin-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .text::before {
    margin-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .cont .text::before {
    margin-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-about .cont .text::before {
    margin-bottom: 10px;
  }
}

.index-about .cont .link {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-left: 20%;
}

@media (max-width: 767px) {
  .index-about .cont .link {
    margin-top: 20px;
  }
}

@media (max-width: 1024px) {
  .index-about .cont .link {
    margin-left: 10%;
  }
}

@media (max-width: 767px) {
  .index-about .cont .link {
    margin-left: 0;
  }
}

.scroll-text {
  overflow: hidden;
  font-family: 'roboto-b';
}

.scroll-text.slide-right .text1 .txt-scroll .scrollbox {
  justify-content: flex-end;
}

.scroll-text.slide-right .text1 .txt-scroll .scrollbox .txt {
  animation: myslideRight 30s infinite linear;
}

.scroll-text .text1 {
  line-height: .9;
  text-transform: uppercase;
  color: #c51333;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.3);
  font-size: 110px;
}

@media (max-width: 1600px) {
  .scroll-text .text1 {
    font-size: 96px;
  }
}

@media (max-width: 1366px) {
  .scroll-text .text1 {
    font-size: 82px;
  }
}

@media (max-width: 1024px) {
  .scroll-text .text1 {
    font-size: 68px;
  }
}

@media (max-width: 991px) {
  .scroll-text .text1 {
    font-size: 54px;
  }
}

@media (max-width: 767px) {
  .scroll-text .text1 {
    font-size: 40px;
  }
}

.scroll-text .text1 .txt-scroll .scrollbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.scroll-text .text1 .txt-scroll .scrollbox .txt {
  animation: myslideLeft 30s infinite linear;
}

@keyframes myslideLeft {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(-90%);
  }
}

@keyframes myslideRight {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(90%);
  }
}

.index-product {
  position: relative;
  padding-top: 55px;
  margin-bottom: 230px;
}

@media (max-width: 1600px) {
  .index-product {
    padding-top: 46px;
  }
}

@media (max-width: 1366px) {
  .index-product {
    padding-top: 37px;
  }
}

@media (max-width: 1024px) {
  .index-product {
    padding-top: 28px;
  }
}

@media (max-width: 991px) {
  .index-product {
    padding-top: 19px;
  }
}

@media (max-width: 767px) {
  .index-product {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-product {
    margin-bottom: 194px;
  }
}

@media (max-width: 1366px) {
  .index-product {
    margin-bottom: 158px;
  }
}

@media (max-width: 1024px) {
  .index-product {
    margin-bottom: 122px;
  }
}

@media (max-width: 991px) {
  .index-product {
    margin-bottom: 86px;
  }
}

@media (max-width: 767px) {
  .index-product {
    margin-bottom: 50px;
  }
}

.index-product .bg-img {
  position: absolute;
  left: 0;
  bottom: -10%;
  z-index: -1;
  transform: translateX(-50%);
}

.index-product .bg-img img {
  display: block;
  height: auto;
  width: 1044px;
}

@media (max-width: 1600px) {
  .index-product .bg-img img {
    width: 895.2px;
  }
}

@media (max-width: 1366px) {
  .index-product .bg-img img {
    width: 746.4px;
  }
}

@media (max-width: 1024px) {
  .index-product .bg-img img {
    width: 597.6px;
  }
}

@media (max-width: 991px) {
  .index-product .bg-img img {
    width: 448.8px;
  }
}

@media (max-width: 767px) {
  .index-product .bg-img img {
    width: 300px;
  }
}

.index-product .product ul li {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 75px;
}

@media (max-width: 767px) {
  .index-product .product ul li {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-product .product ul li {
    margin-top: 64px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li {
    margin-top: 53px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li {
    margin-top: 42px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li {
    margin-top: 31px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li {
    margin-top: 20px;
  }
}

.index-product .product ul li:nth-child(even) {
  flex-direction: row-reverse;
}

.index-product .product ul li:nth-child(even) .item-text {
  padding-left: 280px;
}

@media (max-width: 1600px) {
  .index-product .product ul li:nth-child(even) .item-text {
    padding-left: 224px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li:nth-child(even) .item-text {
    padding-left: 168px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li:nth-child(even) .item-text {
    padding-left: 112px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li:nth-child(even) .item-text {
    padding-left: 56px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li:nth-child(even) .item-text {
    padding-left: 0px;
  }
}

.index-product .product ul li .item-img {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  width: 40.5%;
}

@media (max-width: 767px) {
  .index-product .product ul li .item-img {
    width: 100%;
  }
}

.index-product .product ul li .item-img img {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
}

.index-product .product ul li .item-img b {
  display: block;
  width: 100%;
  height: 130%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
}

.index-product .product ul li .item-text {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 59.5%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
  padding-left: 140px;
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text {
    padding-top: 44px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text {
    padding-top: 38px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text {
    padding-top: 32px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text {
    padding-top: 26px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text {
    padding-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text {
    padding-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text {
    padding-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text {
    padding-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text {
    padding-right: 40px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text {
    padding-right: 30px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text {
    padding-right: 20px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text {
    padding-right: 0px;
  }
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text {
    padding-left: 112px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text {
    padding-left: 84px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text {
    padding-left: 56px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text {
    padding-left: 28px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text {
    padding-left: 0px;
  }
}

.index-product .product ul li .item-text .t1 {
  color: #c51333;
  font-size: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text .t1 {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text .t1 {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text .t1 {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text .t1 {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .t1 {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text .t1 {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text .t1 {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text .t1 {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text .t1 {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .t1 {
    margin-bottom: 10px;
  }
}

.index-product .product ul li .item-text .t2 {
  color: #000;
  font-family: 'roboto-b';
  font-size: 50px;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text .t2 {
    font-size: 44.8px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text .t2 {
    font-size: 39.6px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text .t2 {
    font-size: 34.4px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text .t2 {
    font-size: 29.2px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .t2 {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text .t2 {
    margin-bottom: 35px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text .t2 {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text .t2 {
    margin-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text .t2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .t2 {
    margin-bottom: 15px;
  }
}

.index-product .product ul li .item-text .t3 {
  color: #000;
  line-height: 1;
  font-size: 20px;
  margin-bottom: 110px;
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text .t3 {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text .t3 {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text .t3 {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text .t3 {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .t3 {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text .t3 {
    margin-bottom: 92px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text .t3 {
    margin-bottom: 74px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text .t3 {
    margin-bottom: 56px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text .t3 {
    margin-bottom: 38px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .t3 {
    margin-bottom: 20px;
  }
}

.index-product .product ul li .item-text .t3 span {
  display: inline-block;
  margin-bottom: 10px;
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text .t3 span {
    margin-bottom: 10px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text .t3 span {
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text .t3 span {
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text .t3 span {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .t3 span {
    margin-bottom: 10px;
  }
}

.index-product .product ul li .item-text .t3 span:not(:last-child) {
  margin-right: 35px;
}

@media (max-width: 1600px) {
  .index-product .product ul li .item-text .t3 span:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 1366px) {
  .index-product .product ul li .item-text .t3 span:not(:last-child) {
    margin-right: 25px;
  }
}

@media (max-width: 1024px) {
  .index-product .product ul li .item-text .t3 span:not(:last-child) {
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  .index-product .product ul li .item-text .t3 span:not(:last-child) {
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .t3 span:not(:last-child) {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .index-product .product ul li .item-text .link {
    text-align: right;
  }
}

.index-quality {
  align-items: stretch;
  justify-content: stretch;
  display: flex;
  margin-bottom: 130px;
}

@media (max-width: 767px) {
  .index-quality {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-quality {
    margin-bottom: 110px;
  }
}

@media (max-width: 1366px) {
  .index-quality {
    margin-bottom: 90px;
  }
}

@media (max-width: 1024px) {
  .index-quality {
    margin-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .index-quality {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .index-quality {
    margin-bottom: 30px;
  }
}

.index-quality .left {
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: #c51333;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  width: 34%;
  transform: translateY(-70px);
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 65px;
  padding-left: 100px;
}

@media (max-width: 767px) {
  .index-quality .left {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-quality .left {
    transform: translateY(0);
  }
}

@media (max-width: 1600px) {
  .index-quality .left {
    padding-top: 44px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left {
    padding-top: 38px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left {
    padding-top: 32px;
  }
}

@media (max-width: 991px) {
  .index-quality .left {
    padding-top: 26px;
  }
}

@media (max-width: 767px) {
  .index-quality .left {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-quality .left {
    padding-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left {
    padding-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left {
    padding-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-quality .left {
    padding-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-quality .left {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-quality .left {
    padding-right: 55px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left {
    padding-right: 45px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left {
    padding-right: 35px;
  }
}

@media (max-width: 991px) {
  .index-quality .left {
    padding-right: 25px;
  }
}

@media (max-width: 767px) {
  .index-quality .left {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .index-quality .left {
    padding-left: 83px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left {
    padding-left: 66px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left {
    padding-left: 49px;
  }
}

@media (max-width: 991px) {
  .index-quality .left {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .index-quality .left {
    padding-left: 15px;
  }
}

.index-quality .left .t1 {
  font-family: 'roboto-b';
  line-height: .8;
  text-transform: uppercase;
  font-size: 80px;
  margin-bottom: 85px;
}

@media (max-width: 1600px) {
  .index-quality .left .t1 {
    font-size: 70px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left .t1 {
    font-size: 60px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left .t1 {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .index-quality .left .t1 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .index-quality .left .t1 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index-quality .left .t1 {
    margin-bottom: 72px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left .t1 {
    margin-bottom: 59px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left .t1 {
    margin-bottom: 46px;
  }
}

@media (max-width: 991px) {
  .index-quality .left .t1 {
    margin-bottom: 33px;
  }
}

@media (max-width: 767px) {
  .index-quality .left .t1 {
    margin-bottom: 20px;
  }
}

.index-quality .left .t2 {
  font-size: 20px;
  margin-bottom: 55px;
}

@media (max-width: 1600px) {
  .index-quality .left .t2 {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left .t2 {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left .t2 {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-quality .left .t2 {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-quality .left .t2 {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-quality .left .t2 {
    margin-bottom: 48px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left .t2 {
    margin-bottom: 41px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left .t2 {
    margin-bottom: 34px;
  }
}

@media (max-width: 991px) {
  .index-quality .left .t2 {
    margin-bottom: 27px;
  }
}

@media (max-width: 767px) {
  .index-quality .left .t2 {
    margin-bottom: 20px;
  }
}

.index-quality .left .t2 p:not(:last-child) {
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .index-quality .left .t2 p:not(:last-child) {
    margin-bottom: 26px;
  }
}

@media (max-width: 1366px) {
  .index-quality .left .t2 p:not(:last-child) {
    margin-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-quality .left .t2 p:not(:last-child) {
    margin-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-quality .left .t2 p:not(:last-child) {
    margin-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-quality .left .t2 p:not(:last-child) {
    margin-bottom: 10px;
  }
}

.index-quality .right {
  flex-shrink: 0;
  width: 66%;
}

@media (max-width: 767px) {
  .index-quality .right {
    width: 100%;
  }
}

.index-quality .right img {
  display: block;
  width: 100%;
  height: auto;
}

.index-news {
  margin-bottom: 140px;
}

@media (max-width: 1600px) {
  .index-news {
    margin-bottom: 118px;
  }
}

@media (max-width: 1366px) {
  .index-news {
    margin-bottom: 96px;
  }
}

@media (max-width: 1024px) {
  .index-news {
    margin-bottom: 74px;
  }
}

@media (max-width: 991px) {
  .index-news {
    margin-bottom: 52px;
  }
}

@media (max-width: 767px) {
  .index-news {
    margin-bottom: 30px;
  }
}

.index-news .cont {
  padding-left: 120px;
  padding-right: 120px;
}

@media (max-width: 1600px) {
  .index-news .cont {
    padding-left: 96px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont {
    padding-left: 72px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont {
    padding-left: 48px;
  }
}

@media (max-width: 991px) {
  .index-news .cont {
    padding-left: 24px;
  }
}

@media (max-width: 767px) {
  .index-news .cont {
    padding-left: 0px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont {
    padding-right: 96px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont {
    padding-right: 72px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont {
    padding-right: 48px;
  }
}

@media (max-width: 991px) {
  .index-news .cont {
    padding-right: 24px;
  }
}

@media (max-width: 767px) {
  .index-news .cont {
    padding-right: 0px;
  }
}

.index-news .cont .news-title {
  color: #c51333;
  font-size: 20px;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .index-news .cont .news-title {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .news-title {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .news-title {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .news-title {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .news-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont .news-title {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .news-title {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .news-title {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .news-title {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .news-title {
    margin-bottom: 20px;
  }
}

.index-news .cont .link {
  text-align: center;
  margin-top: 0;
}

@media (max-width: 767px) {
  .index-news .cont .link {
    margin-top: 20px;
  }
}
